<template>
  <text
    :x="opts.x"
    :y="opts.y"
    text-anchor="middle"
    alignment-baseline="middle"
    :font-size="opts.fontSize"
    class="row-number"
  >
    {{ opts.text }}
  </text>
</template>

<script>
export default {
  props: {
    opts: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },
  name: 'RowNumber'
}
</script>

<style scoped lang="sass">
.row-number
  pointer-events: none
  fill: $main
</style>
