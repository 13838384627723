<template>
  <path
    :d="path"
    fill="none"
    :stroke="stroke"
    :stroke-width="strokeWidth"
    class="figure-arrow"
    style="stroke-linecap: round"
  ></path>
</template>

<script>
import { colors } from '@/utils/drawing'

export default {
  props: {
    path: {
      type: String,
      default: null,
      required: true
    },
    color: {
      type: String,
      default: 'grey',
      required: true
    },
    isDetails: {
      type: Boolean,
      required: true
    },
    strokeWidth: {
      type: Number,
      default: 2
    }
  },
  name: 'SizeArrow',
  computed: {
    stroke() {
      return this.isDetails ? colors.grey : colors[this.color]
    }
  }
}
</script>

<style scoped lang="sass">
.figure-arrow
  pointer-events: none
</style>
