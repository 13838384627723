import { render, staticRenderFns } from "./SizeArrow.vue?vue&type=template&id=27ba8490&scoped=true"
import script from "./SizeArrow.vue?vue&type=script&lang=js"
export * from "./SizeArrow.vue?vue&type=script&lang=js"
import style0 from "./SizeArrow.vue?vue&type=style&index=0&id=27ba8490&prod&scoped=true&lang=sass"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27ba8490",
  null
  
)

export default component.exports