<template>
  <line
    :x1="x1"
    :y1="y1"
    :x2="x2"
    :y2="y2"
    :stroke="strokeColor"
    stroke-width="2"
    :stroke-dasharray="strokeDashArray"
  ></line>
</template>

<script>
export default {
  props: {
    x1: {
      type: Number,
      default: 0,
      required: true
    },
    x2: {
      type: Number,
      default: 0,
      required: true
    },
    y1: {
      type: Number,
      default: 0,
      required: true
    },
    y2: {
      type: Number,
      default: 0,
      required: true
    },
    color: {
      type: String,
      default: null
    }
  },
  name: 'Ruler',
  computed: {
    strokeColor() {
      return this.color ? this.color : '#D6D6D6'
    },
    strokeDashArray() {
      return this.color ? '10 10' : '5 5'
    }
  }
}
</script>

<style scoped></style>
